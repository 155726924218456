<template>
    <div>
        <div class="top">
            <div class="title">{{ data.Assessment_Name }}</div>
            <div class="tips">{{ data.Assessment_Description }}</div>
            <div class="info">
                <span>考核时间：{{ $moment(data.Answer_Start_Time).format('YYYY-MM-DD') }}~{{
                $moment(data.Answer_End_Time).format('YYYY-MM-DD') }}</span>
                <span>发起人：{{ data.Publish_User_Name }}</span>
            </div>
        </div>
        <div class="center">
            <div class="item" v-for="(item, index) in data.TemplateParents" :key="index">
                <div class="bigTit">{{ item.Parent_Title }}({{ item.Parent_Score_Max }}分)</div>
                <div class="bigTopic" v-if="item.Is_SetScore == 1">
                    <van-field v-model="item.Self_Assessment_Score"
                        v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number" label="自评分:"
                        placeholder="请输入" :disabled="data.IsSelfComplate==1 ? true : false"
                        @input="changeScoreSelf(arguments[0], item.Parent_Score_Max, index)" />

                    <van-field v-model="item.ManUser_Score"
                        v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                        type="number" label="考核评分：" placeholder="请输入" :disabled="data.IsComplete == 0 ? true : false"
                        @input="changeScore(arguments[0], item.Parent_Score_Max, index)" />
                </div>

                <div class="childBox" v-if="item.Children.length">
                    <div class="child" v-for="(val, ind) in item.Children" :key="ind">
                        <div class="smallTit">{{ val.Child_Title }}(标准分{{ val.Child_Score_Max }}分)</div>
                        <div class="smallTopic">
                            <van-field v-model="val.Self_Assessment_Score"
                                v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number" label="自评分:"
                                placeholder="请输入" :disabled="data.IsSelfComplate==1 ? true : false"
                                @input="changeChildSelf(arguments[0], val.Child_Score_Max, index, ind)" />

                            <van-field v-model="val.ManUser_Score"
                                v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                                type="number" label="考核评分：" placeholder="请输入"
                                :disabled="data.IsComplete == 0 ? true : false"
                                @input="changeChild(arguments[0], val.Child_Score_Max, index, ind)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn">
            <van-button type="info" style="width: 90%;" @click="submit">提交</van-button>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    data() {
        return {
            user: this.whale.user.get(),
            data: {},
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/PFM/PfmRecord/GetAssessmentDetails?guid=" + self.$route.query.guid + '&teacherId=' + self.user.ID,
                completed(m) {
                    console.log(m)
                    self.data = m.DATA
                },
            });
        },
        changeScore(val, e, i) {
            if (val > e) {
                this.data.TemplateParents[i].ManUser_Score = e
                Toast.fail('分数不能超过标准分的分值')
            }
        },
        changeScoreSelf(val, e, i) {
            if (val > e) {
                this.data.TemplateParents[i].Self_Assessment_Score = e
                Toast.fail('分数不能超过标准分的分值')
            }
        },


        changeChild(val, e, p, i) {
            if (val > e) {
                this.data.TemplateParents[p].Children[i].ManUser_Score = e
                Toast.fail('分数不能超过标准分的分值')
            }
        },
        changeChildSelf(val, e, p, i) {
            if (val > e) {
                this.data.TemplateParents[p].Children[i].Self_Assessment_Score = e
                Toast.fail('分数不能超过标准分的分值')
            }
        },

        submit() {
            if (this.data.AssessmentType == 2) {
                this.selfSubmit()
            } else if (this.data.AssessmentType == 3) {
                this.checkSubmit()
            } else {
                if (this.data.IsSelfComplate != 1) {
                    this.selfSubmit()
                } else {
                    this.checkSubmit()
                }
            }
        },
        selfSubmit() {
            let data = {
                IsComplete: 0,
                IsSelfComplate: 1,
                Assessment_Guid: this.data.Assessment_Guid,
                MainId: this.data.Record_Main_Id,
                assRecords: []
            }
            this.data.TemplateParents.map(item => {
                if (item.Children.length) {
                    item.Children.map(val => {
                        let obj = {
                            Assessment_Details_Id: val.ID,
                            Self_Assessment_Score: val.Self_Assessment_Score,
                            ManUser_Score: val.ManUser_Score
                        }
                        data.assRecords.push(obj)
                    })
                } else {
                    let obj = {
                        Assessment_Details_Id: item.ID,
                        Self_Assessment_Score: item.Self_Assessment_Score,
                        ManUser_Score: item.ManUser_Score
                    }
                    data.assRecords.push(obj)
                }
            })

            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/PFM/PfmRecord/CreateOrUpdateScore",
                data: data,
                completed() {
                    self.$router.push("/check/selfback")
                },
            });
        },
        checkSubmit() {
            let data = {
                IsComplete: 1,
                IsSelfComplate: 1,
                Assessment_Guid: this.data.Assessment_Guid,
                MainId: this.data.Record_Main_Id,
                assRecords: []
            }
            this.data.TemplateParents.map(item => {
                if (item.Children.length) {
                    item.Children.map(val => {
                        let obj = {
                            Assessment_Details_Id: val.ID,
                            Self_Assessment_Score: val.Self_Assessment_Score,
                            ManUser_Score: val.ManUser_Score
                        }
                        data.assRecords.push(obj)
                    })
                } else {
                    let obj = {
                        Assessment_Details_Id: item.ID,
                        Self_Assessment_Score: item.Self_Assessment_Score,
                        ManUser_Score: item.ManUser_Score
                    }
                    data.assRecords.push(obj)
                }
            })
            let self = this
            this.whale.remote.getResult({
                url: 'api/Mobile/PFM/PfmRecord/ManCreateOrUpdateScore',
                data: data,
                completed() {
                    self.$router.push("/check/back")
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.top {
    background: #fff;
    padding-top: 10px;

    .title {
        width: 80%;
        margin: 20px auto;
        color: #0080ee;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }

    .tips {
        color: #222222;
        border-bottom: 1px dashed #222222;
        padding-bottom: 20px;
        width: 90%;
        margin: 0 auto;
        font-size: 15px;
    }

    .info {
        color: #98a3ad;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        padding: 10px;
    }
}

.center {
    background: #fff;
    padding-bottom: 10px;

    .item {
        .bigTit {
            background: #f0f9ff;
            border-left: 3px solid #0195ff;
            font-weight: bold;
            padding: 10px 0 10px 20px;
            color: #0195ff;
            margin-bottom: 10px;
        }

        .bigTopic {
            background: #f6f7fb;
            width: 90%;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
        }

        .childBox {
            width: 90%;
            margin: 0 auto;

            .child {
                .smallTit {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .smallTopic {
                    background: #f6f7fb;
                    /* width: 90%; */
                    margin: 0 auto;
                    padding: 20px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.btn {
    width: 100%;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
</style>